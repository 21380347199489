.section {
  padding: 40px 0 80px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #141414;
}

.driver {
  width: 65px;
  height: 4px;
  margin-right: 30px;
  background-color: #000000;
}

.tabs {
  position: relative;
  margin-bottom: 100px;
  padding: 80px 160px 80px 80px;
  border-radius: 30px;
  background-color: rgba(56, 138, 123, 0.4);

  ul {
    position: absolute;
    top: 0;
    left: 80px;
    transform: translateY(-50%);
    display: flex;
    column-gap: 16px;
  }

  li {
    padding: 16px 40px;
    background-color: #ffffff;
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
    color: #29665a;
    border-radius: 60px;
    cursor: pointer;
    box-shadow: -15px 4px 22px rgba(0, 0, 0, 0.14);
    transition: all .2s ease;

    &:hover,
    &.active {
      background-color: #29665a;
      color: #ffffff;
    }
  }

  p {
    font-size: 20px;
    line-height: 40px;
    color: #141414;
  }
}

.manager {
  margin-bottom: 150px;
  padding: 30px 80px 30px 30px;
  background-color: #ffffff;
  border: 1px solid #29665a;
  border-radius: 30px;

  & > div {
    display: flex;
    column-gap: 64px;
  }

  img {
    width: 400px;
    height: 400px;
    border-radius: 30px;
  }

  h3 {
    margin-bottom: 8px;
    padding-top: 20px;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: #141414;
  }

  i {
    display: block;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 40px;
    color: #141414;
  }

  ul {
    display: flex;
    column-gap: 50px;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      background-color: rgba(56, 138, 123, 0.2);
      border-radius: 50%;
    }

    span {
      font-size: 20px;
      line-height: 40px;
      color: #141414;
    }
  }
}

.participant {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 30px;
  row-gap: 60px;
}