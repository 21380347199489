.section {
  padding: 50px 0;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #141414;
}

.driver {
  width: 65px;
  height: 4px;
  margin-right: 30px;
  background-color: #000000;
}

@media only screen and (max-width: 720px) {
  .title {
    font-size: 32px;
  }

  .driver {
    display: none;
  }
}

@media only screen and (max-width: 520px) {
  .title {
    font-size: 24px;
  }
}