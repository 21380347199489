.section {
  padding: 40px 0 80px;
}

.title {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 44px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.card {
  display: flex;
  column-gap: 20px;
  width: calc((100% - 30px) / 2);
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 14px;

  &:nth-child(2n) {
    flex-direction: row-reverse;

    .info {
      a {
        align-self: flex-start;
      }
    }
  }

  .image {
    flex-shrink: 0;
    width: 240px;
  }

  .info {
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: auto;
    }

    a {
      align-self: flex-end;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 4px;
      font-weight: 500;
      font-size: 20px;
      color: #2E8373;
      transition: all .2s ease;

      &:hover {
        column-gap: 8px;
        opacity: .9;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 991px) {
  .title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 700px) {
  .card {
    flex-direction: column;

    &:nth-child(2n) {
      flex-direction: column;

      .info {
        a {
          align-self: flex-end;
        }
      }
    }

    .image {
      width: 100%;
      height: 300px;
      margin-bottom: 10px;

      img {
        height: 300px;
      }
    }

    .info {
      p {
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .title {
    font-size: 24px;
  }
}