.section {
  padding: 50px 0 0;
}

.content {
  padding: 24px;
  border: 1px solid #d8d8d8;
  border-radius: 20px;
}

.map {
  height: 400px;
}

@media only screen and (max-width: 900px) {
  .map {
    height: 300px;
  }
}
