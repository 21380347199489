@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*,
*::before,
*::after {
   box-sizing: border-box;
}

body {
   margin: 0;
   padding: 0;
   font-family: Roboto, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
   margin: 0;
}

ul {
   margin: 0;
   padding: 0;
   list-style-type: none;
}

a {
   text-decoration: none;
}

img {
   object-fit: cover;
}

button {
   cursor: pointer;
}

.container {
   max-width: 1320px;
   width: 100%;
   margin: 0 auto;
   padding: 0 20px;
}