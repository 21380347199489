.card {
  height: 440px;
  padding: 24px 24px 40px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 12px;

  div {
    display: flex;
    column-gap: 4px;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: 200px;
    border-radius: 12px;
  }

  h3 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #181720;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  p {
    display: -webkit-box;
    height: 56px;
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 28px;
    color: rgba(24, 23, 32, 0.5);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  a {
    display: inline-flex;
    align-items: center;
    column-gap: 4px;
    color: #29665a;
    transition: all .2s ease;

    &:hover {
      column-gap: 8px;
    }
  }

  span {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 640px) {
  .card {
    img {
    }
  }
}