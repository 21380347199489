.card {
  position: relative;
  width: calc((100% - 60px) / 3);
  border-radius: 14px;

  .body {
    position: relative;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px 16px 32px;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 14px;
    transition: all .2s ease;
  }

  &:hover {
    .body {
      transform: translateY(-60px);
    }
  }

  img {
    width: 100%;
    height: 220px;
    margin-bottom: 20px;
    border-radius: 12px;
  }

  h3 {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #181720;
  }

  p {
    flex-grow: 1;
    display: -webkit-box;
    margin-bottom: 25px;
    line-height: 27px;
    color: rgba(24, 23, 32, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .count {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  span {
    line-height: 28px;
    color: rgba(24, 23, 32, 0.6);
  }

  a {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    width: 100%;
    padding: 32px 32px 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
    background-color: #29665a;
    color: #ffffff;
    border-radius: 14px;
    transition: all .2s ease;

    &:hover {
      column-gap: 8px;
      opacity: .9;
    }
  }
}

@media only screen and (max-width: 990px) {
  .card {
    width: calc((100% - 30px) / 2);
  }
}

@media only screen and (max-width: 700px) {
  .card {
    width: 100%;

    img {
      height: 320px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .card {
    img {
      height: 220px;
    }
  }
}
