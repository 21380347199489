.section {
  padding: 70px 0 80px;
  background-image: url("../../../../assets/images/restaurants.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
}

.description {
  max-width: 520px;
  margin-bottom: 80px;
  padding-left: 95px;
  font-size: 20px;
  line-height: 32px;
}

.driver {
  width: 65px;
  height: 4px;
  margin-right: 30px;
  background-color: #ffffff;
}

.slide {
  display: flex;
  column-gap: 40px;
  cursor: pointer;

  > div {
    width: calc((100% - 40px) / 2);
  }

  .image {
    img {
      border-radius: 20px;
    }
  }

  .info {
    padding-top: 30px;
    overflow: hidden;

    h3 {
      display: -webkit-box;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    p {
      display: -webkit-box;
      margin-bottom: 0;
      font-size: 24px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 6;
      line-clamp: 6;
      -webkit-box-orient: vertical;
    }
  }
}

@media only screen and (max-width: 991px) {
  .slide {
    column-gap: 20px;

    .image {
      width: 300px;

      img {
        height: 300px;
      }
    }

    .info {
      width: calc(100% - 320px);
      padding-top: 10px;

      h3 {
        margin-bottom: 10px;
        font-size: 28px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .title {
    font-size: 32px;
  }

  .driver {
    display: none;
  }

  .slide {
    flex-direction: column;

    .image,
    .info {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 520px) {
  .title {
    font-size: 24px;
  }
}