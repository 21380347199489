.wrapper {
   position: relative;
}

.prevBtn,
.nextBtn {
   position: absolute;
   top: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 45px;
   height: 45px;
   background-color: transparent;
   border-radius: 50%;
   border: 1px solid;
   cursor: pointer;
   transition: all .3s ease;
}

.prevBtn--green,
.nextBtn--green {
   color: #29665a;
   border-color: #29665a;

   &:hover {
      background-color: #29665a;
      color: #ffffff;
   }
}

.prevBtn--white,
.nextBtn--white {
   color: white;
   border-color: white;

   &:hover {
       color: #29665a;
       background-color: #ffffff;
   }
}

.prevBtn {
   left: -75px;
}

.nextBtn {
   right: -75px;
}

@media only screen and (max-width: 1460px) {
   .prevBtn {
      left: -50px;
   }

   .nextBtn {
      right: -50px;
   }
}

@media only screen and (max-width: 1460px) {
   .prevBtn,
   .nextBtn {
      top: -60px;
   }

   .prevBtn {
      left: calc(100% - 130px);
   }

   .nextBtn {
      right: 20px;
   }
}

@media only screen and (max-width: 520px) {
   .prevBtn,
   .nextBtn {
      width: 36px;
      height: 36px;
   }

   .prevBtn {
      left: calc(100% - 100px);
   }
}