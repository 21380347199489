.section {
  padding: 40px 0 80px;
}

.search {
  position: relative;
  margin-bottom: 40px;

  input {
    width: 100%;
    padding: 24px 24px 24px 72px;
    font-size: 22px;
    line-height: 26px;
    color: #141414;
    outline: none;
    border: 1px solid #D8D8D8;
    border-radius: 60px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 28px;
    transform: translateY(-50%);
    color: rgba(0, 0, 0, .5);
  }
}

.content {
  display: flex;
  column-gap: 20px;

  .left {
    width: 250px;
    flex-shrink: 0;

    .parts {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .part {
      padding: 20px;
      border: 1px solid #d8d8d8;
      border-radius: 20px;

      h3 {
        margin-bottom: 28px;
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
      }

      ul {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }

      li {
        font-size: 20px;
        line-height: 22px;
        color: #333333;
      }

      label {
        display: flex;
        align-items: center;
        column-gap: 12px;
        cursor: pointer;
      }
    }
  }

  .right {
    flex-grow: 1;

    .spinner {
      display: grid;
      place-items: center;
      width: 100%;
      height: 300px;
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    li {
      display: flex;
      column-gap: 30px;
      padding: 20px;
      border: 1px solid #d8d8d8;
      border-radius: 20px;
    }

    img {
      width: 240px;
      flex-shrink: 0;
      border-radius: 10px;
    }

    div {
      display: flex;
      flex-direction: column;
    }

    h3 {
      margin-bottom: 10px;
      font-size: 24px;
      line-height: 28px;
    }

    p {
      flex-grow: 1;
      margin-bottom: 10px;
      font-size: 18px;
      color: rgba(24, 23, 32, 0.5);
    }

    a {
      display: inline-flex;
      align-items: center;
      column-gap: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 34px;
      color: #285D53;
      transition: all .2s ease;

      &:hover {
        column-gap: 12px;
      }
    }

    span {
      text-decoration: underline;
    }
  }
}

.buttonBox {
  display: none;
  justify-content: center;

  button {
    padding: 20px 50px;
    background-color: #29665a;
    color: #ffffff;
    border-radius: 10px;
    transition: .2s ease;

    &:hover {
      background-color: #285D53;
    }
  }
}