.section {
  padding: 50px 0 100px;
}

.title {
  display: flex;
  column-gap: 30px;
  align-items: center;
  margin-bottom: 64px;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #141414;
}

.driver {
  width: 65px;
  height: 4px;
  background-color: #000000;
}

.slide {
  position: relative;
  text-align: center;
  color: #ffffff;
  transition: all .2s ease;

  img {
    width: 100%;
    height: 350px;
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
    object-fit: cover;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 150px 40px 50px;
    cursor: pointer;
    opacity: 0;
  }

  &:hover div {
    opacity: 1;
  }

  h3 {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 20px;
    line-height: 31px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}