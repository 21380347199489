.section {
  padding: 50px 0 80px;
  background-color: #d7e8e5;
  background-image: url("../../../../assets/images/hotel_bg_1.png"), url("../../../../assets/images/hotel_bg_2.png"), url("../../../../assets/images/hotel_bg_3.png");
  background-repeat: no-repeat;
  background-position: left 50px, 100px 550px, right 120px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #141414;
}

.driver {
  width: 65px;
  height: 4px;
  margin-right: 30px;
  background-color: #000000;
}

.slide {
  cursor: pointer;

  img {
    width: 100%;
    height: 252px;
    margin-bottom: 25px;
    object-fit: cover;
    border-top-left-radius: 150px;
    border-top-right-radius: 150px;
  }

  h3 {
    display: -webkit-box;
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media only screen and (max-width: 720px) {
  .title {
    font-size: 32px;
  }

  .driver {
    display: none;
  }

  .slide {
    img {
      height: 300px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .title {
    font-size: 24px;
  }

  .slide {
    padding: 0 20px;

    img {
      border-radius: 12px;
    }
  }
}