.section {
  padding: 40px 0 80px;
}

.title {
  margin-bottom: 70px;
  text-align: center;
  font-weight: 700;
  font-size: 44px;
}

.slider {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 80px;
}

@media only screen and (max-width: 1200px) {
  .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 991px) {
  .title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 520px) {
  .title {
    font-size: 24px;
  }
}