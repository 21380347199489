.footer {
  background: linear-gradient(120.22deg, #2C786A 50.01%, #329C88 89.96%);
  color: #ffffff;
}

.content {
  background-image: url("../../assets/images/globe.svg");
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: right calc((100% - 100px));
}

.top {
  padding: 40px 0;

  &Content {
    display: flex;
    align-items: flex-end;
    column-gap: 280px;
  }

  span {
    display: block;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
  }

  li:not(:last-child) {
    margin-bottom: 15px;
  }

  a {
    font-size: 21px;
    line-height: 25px;
    transition: all .2s ease;
    color: #ffffff;

    &:hover {
      opacity: .75;
    }
  }

  .visit {
    display: flex;
    gap: 10px;

    &Item {
      padding: 8px 10px;
      text-align: center;
      background-color: #ffffff;
      color: #000000;
      border-radius: 4px;
    }

    &Name {
      margin-bottom: 5px;
      font-size: 16px;
    }

    &Count {
      font-weight: 500;
      font-size: 24px;
    }
  }
}

.driver {
  height: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(7px);
}

.bottom {
  padding: 20px 0;
  background-color: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(14px);

  &Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Text {
    font-size: 17px;
    line-height: 28px;
    opacity: 0.8;

    &:last-child li {
      text-align: end;
    }
  }

  &Icons {
    display: flex;
    column-gap: 10px;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background-color: rgba(255, 255, 255, .2);
      border-radius: 50%;
      cursor: pointer;
      transition: all .2s ease;

      &:hover {
        background-color: rgba(255, 255, 255, .05);
      }
    }

    svg {
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 991px) {
  .top {
    &Content {
      column-gap: 100px;
    }
  }
}

@media only screen and (max-width: 786px) {
  .top {
    &Content {
      justify-content: space-between;
      align-items: flex-start;
      column-gap: 0;

      .visit {
        flex-direction: column;
      }
    }
  }

  .bottom {
    &Content {
      display: flex;
      justify-content: center;
    }

    &Text {
      display: none;
    }
  }
}