.section {
  position: relative;
  margin-bottom: 140px;
}

.slider {
  position: relative;
  height: 600px;
}

.slide {
  position: relative;
  height: 100% !important;

  .curtain {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
  }

  .image {
    width: 100%;
    height: 600px;
  }

  .info {
    position: absolute;
    top: 300px;
    transform: translateY(-50%);
    max-width: 900px;
    width: 100%;
    padding-right: 20px;
  }

  .title {
    font-weight: 800;
    line-height: 44px;
    font-size: 36px;
    color: #ffffff;
  }
}

.statistics {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  max-width: 1040px;
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.09);
  border-radius: 30px;

  .item {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    background-color: #d4dfdd;
    border-radius: 50%;
  }

  .text {
    font-weight: 600;
    font-size: 20px;
  }
}

@media only screen and (max-width: 1040px) {
  .statistics {
    width: 90%;
    padding: 30px;
  }
}

@media only screen and (max-width: 990px) {
  .slider {
    height: 500px;
  }

  .slide {
    .image {
      height: 500px;
    }

    .info {
      top: 200px;
    }

    .title {
      line-height: 40px;
      font-size: 32px;
    }
  }

  .statistics {
    .item {
      flex-direction: column;
      row-gap: 10px;
    }

    .icon {
      width: 50px;
      height: 50px;
    }

    .text {
      font-size: 16px;
    }
  }

}

@media only screen and (max-width: 720px) {
  .section {
    margin-bottom: 260px;
  }

  .slider {
    height: 380px;
  }

  .slide {
    .image {
      height: 400px;
    }

    .info {
      top: 150px;
    }

    .title {
      line-height: 32px;
      font-size: 24px;
    }
  }

  .statistics {
    transform: translate(-50%, 0);
    width: 100%;
    padding: 30px 20px;
    box-shadow: none;
    border-radius: 0;

    .item {
      height: 100%;
      padding: 20px;
      border: 1px solid #d8d8d8;
    }
  }
}

@media only screen and (max-width: 500px) {
  .slider {
    height: 300px;
  }

  .slide {
    .image {
      height: 300px;
    }

    .info {
      display: none;
    }
  }

  .statistics {
    .text {
      font-size: 24px;
    }
  }
}