.section {
  padding-bottom: 20px;
}

.slider {
  position: relative;
}

.slide {
  position: relative;
  height: 600px;

  .curtain {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

@media only screen and (max-width: 720px) {
  .slide {
    height: 400px;

    img {
      height: 400px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .slide {
    height: 300px;

    img {
      height: 300px;
    }
  }
}