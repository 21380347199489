.card {
  width: calc((100% - 60px) / 3);
  padding: 20px 20px 28px;
  background-color: #ffffff;
  border: 1px solid #29665a;
  border-radius: 30px;
  transition: all .2s ease;

  &:hover {
    transform: translateY(-32px);
    box-shadow: 0 4px 70px rgba(0, 0, 0, 0.15);
  }

  img {
    margin-bottom: 20px;
    width: 100%;
    height: 275px;
    object-fit: cover;
    border-radius: 30px;
  }

  h3 {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 27px;
    line-height: 34px;
  }

  i {
    font-size: 24px;
    line-height: 40px;
    color: #141414;
    opacity: 0.6;
  }
}