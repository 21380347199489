.section {
  padding: 30px 0 40px;
}

.breadcrumb {
  margin-bottom: 20px;

  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #285D53;
    transition: all .2s ease;

    &:hover {
      opacity: .8;
    }
  }
}

.name {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 48px;
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
}

.photo {
  margin-bottom: 15px;
  width: 100%;
  max-height: 400px;
  border-radius: 10px;
}

.info {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
}

.slider {
  margin-top: 30px;

  h3 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 32px;
  }

  img {
    width: 100%;
    height: 300px;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .name {
    font-size: 36px;
  }
}

@media only screen and (max-width: 700px) {
  .breadcrumb {
    display: none;
  }

  .name {
    font-size: 24px;
  }

  .slider {
    h3 {
      margin-bottom: 20px;
      font-size: 24px;
    }
  }
}