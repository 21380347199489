.section {
  padding: 50px 0;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: #141414;
}

.driver {
  width: 65px;
  height: 4px;
  margin-right: 30px;
  background-color: #000000;
}

.description {
  max-width: 520px;
  margin-bottom: 80px;
  padding-left: 95px;
  font-size: 20px;
  line-height: 32px;
}

.map {
  position: relative;

  svg {
    stroke: #fff;

    path {
      position: relative;

      fill: rgba(56, 138, 123, 0.3);
      cursor: pointer;
      outline: none;

      &[aria-checked='true'] {
        fill: rgba(56, 43, 168, 1);
      }

      &[aria-current='true'] {
        fill: rgba(56, 43, 168, 0.83);
      }

      &[id="nz-can"] {
        fill: rgba(56, 43, 168, 0.6);
      }

      &:hover {
        fill: rgba(56, 138, 123, 0.4);
      }

      &:focus {
        fill: rgba(168, 43, 43, 0.6);
      }
    }
  }

  .selected {
    position: absolute;
    top: -50px;
    right: 0;
  }

  .name {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .image {
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1040px) {
  .map {
    .image {
      width: 300px;
      height: 200px;
    }
  }
}

@media only screen and (max-width: 720px) {
  .title {
    font-size: 32px;
  }

  .driver {
    display: none;
  }

  .map {
    .name {
      font-size: 16px;
    }

    .image {
      width: 200px;
      height: 150px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .section {
    display: none;
  }
}