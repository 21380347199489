.section {
  padding: 50px 0 60px;
}

.content {
  display: flex;
  align-items: start;
  column-gap: 50px;
}

.name {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 48px;
}

.title {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
}

.photo {
  margin-bottom: 15px;
  height: 387px;
  border-radius: 10px;
}

.info {
  margin-bottom: 15px;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
}

.addTitle {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

.addInfo {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
}

.addition {
  position: sticky;
  top: 20px;
  flex-shrink: 0;
  width: 437px;
  padding: 50px 36px 20px;
  border: 1px solid #29665a;
  border-radius: 30px;

  h3 {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  li {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  .icon {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: rgba(40, 93, 83, 0.2);
    border-radius: 50%;
  }

  .text {
    font-size: 20px;
    line-height: 31px;
  }

  .link {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 20px;
    line-height: 32px;
    color: #141414;
    cursor: pointer;
    transition: .15s ease;

    &:hover {
      color: #196DEB;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .maps {
    display: flex;
    column-gap: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .content {
    flex-direction: column-reverse;
  }

  .name {
    font-size: 36px;
  }

  .addition {
    position: static;
    width: 100%;
    padding: 0;
    border: none;

    ul {
      flex-wrap: wrap;
      flex-direction: row;
      column-gap: 20px;
    }

    li {
      width: calc((100% - 20px) / 2);
      padding: 10px;
      box-shadow: 0 4px 34px rgba(0, 0, 0, .1);
      border-radius: 12px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .addition {
    li {
      flex-direction: column;
      text-align: center;
    }

    .icon {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .name {
    font-size: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .addition {
    li {
      width: 100%;
    }
  }
}
