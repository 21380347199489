.section {
  padding: 30px 0;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 40px;
  color: #141414;
}

.description {
  margin-bottom: 80px;
  padding-left: 95px;
  text-align: justify;
  font-size: 20px;
  line-height: 32px;
}

.driver {
  width: 65px;
  height: 4px;
  margin-right: 30px;
  background-color: #000000;
}

.slide {
  cursor: pointer;

  img {
    width: 100%;
    height: 252px;
    margin-bottom: 15px;
    border-top-left-radius: 150px;
    border-top-right-radius: 150px;
  }

  h3 {
    display: -webkit-box;
    font-weight: 700;
    font-size: 24px;
    color: #181720;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media only screen and (max-width: 720px) {
  .title {
    font-size: 32px;
  }

  .description {
    padding-left: 0;
  }

  .driver {
    display: none;
  }

  .slide {
    img {
      border-radius: 20px;
    }

    h3 {
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .title {
    font-size: 24px;
  }

  .slide {
    img {
      height: 320px;
    }
  }
}