.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2001;
  width: 100%;
  height: 86px;
  padding: 16px 0;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0 1px 0;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  column-gap: 10px;
  max-width: 250px;

  &Title {
    font-weight: 500;
    font-size: 18px;
    color: #141414;
  }
}

.navigation {
  ul {
    display: flex;
    column-gap: 16px;
  }

  a {
    position: relative;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #141414;
    transition: all .2s ease;

    &:hover,
    &.active {
      color: #285D53;

      &:before {
        content: '';
        position: absolute;
        top: 120%;
        left: 50%;
        transform: translateX(-50%);
        width: 55px;
        height: 2px;
        background-color: #29665a;
      }
    }
  }

  .icon {
    display: none;
  }
}

.settings {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.language {
  position: relative;
  z-index: 20000;

  &Box {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 4px 12px 4px 4px;
    font-size: 18px;
    color: #141414;
    user-select: none;
    background-color: rgba(56, 138, 123, .2);
    border-radius: 50px;
    cursor: pointer;
    transition: all .2s ease;
  }

  &Icon {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background-color: rgba(56, 138, 123, .1);
    border-radius: 50%;
    transition: all .2s ease;
  }

  &:hover &Icon {
    background-color: rgba(56, 138, 123, .3);
  }

  ul {
    position: absolute;
    top: 110%;
    z-index: 50;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 34px rgba(0, 0, 0, .2);
    visibility: hidden;
    opacity: 0;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  li {
    padding: 8px 12px;
    cursor: pointer;
    transition: all .2s ease;

    &:hover {
      background-color: rgba(56, 138, 123, .3);
    }
  }
}

.search {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 12px 15px;
  background-color: rgba(56, 138, 123, .1);
  color: #141414;
  border: 1px solid #29665a;
  border-radius: 50px;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    background-color: #29665a;
    color: #ffffff;
  }

  span {
    font-size: 18px;
    line-height: 21px;
  }
}

.menu {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .logo {

    &Title {
      font-size: 14px;
    }
  }

  .navigation {
    a {
      font-size: 14px;
    }
  }

  .settings {
    column-gap: 10px;
  }

  .language {
    &Box {
      font-size: 14px;
    }

    &Icon {
      width: 32px;
      height: 32px;
    }
  }

  .search {
    padding: 8px 10px;

    span {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 1140px) {
  .navigation {
    a {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 240px;
    height: 100vh;
    transform: translateX(-240px);
    padding: 60px 10px 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 0 3.2px;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;

    &.open {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }

    ul {
      flex-direction: column;
    }

    a {
      display: block;
      padding: 20px;
      border-radius: 12px;

      &:hover,
      &.active {
        background-color: #285D53;
        color: #ffffff;

        &:before {
          display: none;
        }
      }
    }

    .icon {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
    }
  }

  .menu {
    display: block;
  }
}

@media only screen and (max-width: 520px) {
  .header {
    height: 72px;

    :global(.container) {
      padding: 0 10px;
    }
  }

  .logo {

    &Title {
      display: none;
    }
  }

  .settings {
    column-gap: 10px;
  }

  .language {
    &Box {
      font-size: 14px;
    }

    &Icon {
      width: 28px;
      height: 28px;
    }
  }

  .search {
    padding: 5px 10px;

    span {
      font-size: 14px;
    }
  }
}