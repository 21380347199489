.section {
  padding: 50px 0 60px;
  background-color: rgba(56, 138, 123, 0.2);
}

.title {
  display: flex;
  column-gap: 30px;
  align-items: center;
  margin-bottom: 30px;
  font-size: 32px;
  color: #141414;
}

.divider {
  width: 65px;
  height: 4px;
  background-color: #000000;
}

.audioBox {
  display: flex;
  column-gap: 40px;
  align-items: center;
  margin-top: 50px;
  padding: 32px 48px;
  background-color: #ffffff;
  border-radius: 20px;

  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #141414;
  }
}

.videoBox {
  height: 600px;
  margin-top: 50px;
}

.slide {
  img {
    height: 432px;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .videoBox {
    height: 300px !important;
  }
}