.card {
  display: flex;
  flex-direction: column;
  width: calc((100% - 60px) / 3);
  padding: 30px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 14px;

  div {
    height: 200px;
    margin-bottom: 15px;
  }

  img {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    border-radius: 12px;
  }

  h3 {
    display: -webkit-box;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #181720;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    flex-grow: 1;
    display: -webkit-box;
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 28px;
    color: rgba(24, 23, 32, 0.5);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  a {
    display: inline-block;
    padding: 16px 50px;
    background-color: rgba(56, 138, 123, 0.2);
    color: #29665a;
    border: 1px solid rgba(40, 93, 83, 0.5);
    border-radius: 10px;
    transition: all .2s ease;

    &:hover {
      background-color: #29665a;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 990px) {
  .card {
    width: calc((100% - 30px) / 2);
  }
}

@media only screen and (max-width: 700px) {
  .card {
    width: 100%;

    div {
      height: 300px;
    }
  }
}

@media only screen and (max-width: 520px) {
  .card {
    div {
      height: 200px;
    }
  }
}