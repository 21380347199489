.section {
  padding: 20px 0;
}

.breadcrumb {
  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #285D53;
    transition: all .2s ease;

    &:hover {
      opacity: .8;
    }
  }
}

@media only screen and (max-width: 700px) {
  .section {
    display: none;
  }
}